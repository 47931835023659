$secondary: #ccebff;
$tertiary: rgb(64, 70, 84);
$primary: rgb(1, 122, 203);
/*$card-border-radius: 1.5rem;*/

$btn-border-radius: 1.5rem;
$font-family-sans-serif: "Inter", "Arial";
$gray-100: #efeded;
$gray-800: #002e6d;
$gray-600: #adb5bd;
$orange: rgb(209, 73, 5);
$yellow: rgb(255, 242, 235);
$blue: #017ACD;





$success: #457E5C;
$warn: rgb(209, 73, 5);
$danger: #B32123;
$body-color: #4a4a4a;



@import "bootstrap/scss/bootstrap";


.broker-logo {
    max-width: 260px;
}

.phone-button {
    margin-top: 15px !important;
}

.image-splash {
    max-width: 650px;
    border-radius: 2.5rem !important;
}

.card-main {
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;
    border-bottom: 0;
}

.logo-small {
    max-width: 250px;
}

.badge-summary {
    font-size: 16px !important;
    margin-right: 4px !important;
    margin-top: 6px !important;
}

.btn-icon {
    border: 0 !important;
}

.btn-menu {
    font-size: 14px !important;
    border: 0 !important;
    color: rgb(0, 58, 92);
    background-color: white !important;
}

.btn-menu:hover {
    border: 0 !important;
    color: rgb(0, 58, 92);
    background-color: $secondary !important;
}

.btn-menu-active {
    font-size: 14px !important;
    border: 0 !important;
    color: $secondary !important;
    background-color: $tertiary !important;
}

.btn-menu-active:hover {
    border: 0 !important;
    color: white !important;
    background-color: #003b5e !important;
}

.card-summary,
.card-summary-alt {
    max-height: 365px !important;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 365px !important;
    margin-bottom: 24px !important;
}

.card-summary-alt {
    background-color: rgb(204, 235, 255) !important;
}

.card-top {
    border-radius: 1.5rem 1.5rem 0px 0px !important;
    border-bottom: 0 !important;
    background-color: $primary;
    color: white;
}

.card-outlet {
    border-radius: 1.5rem !important;
}

.card-sidebar {
    border-radius: 1.5rem !important;
    background-color: $secondary;
}

.clicker {
    cursor: pointer;
}

.container-main {
    background-color: $primary !important;
    color: white !important;
    border-top: 0;
    margin-top: 0px;
}

.dropdown-toggle {
    font-size: 20px;
    font-weight: 400;
}

.dropdown-item {
    font-size: 14px !important;
    color: $primary !important;
}

.dropdown-item:hover {
    background-color: $secondary !important;
}

.dropdown-toggle:hover {
    background-color: white !important;
    color: $primary !important;
}

/* meh */

.nav-link {
    color: $body-bg !important;
    padding-left: 1.05rem !important;
    padding-right: 1.05rem !important;
}

.nav-link:hover {
    background-color: $tertiary !important;
}

.nav-link-selected,
.nav-link-selected:hover {
    background-color: $body-bg !important;
    color: $body-color !important;
    /*padding-top: 0px !important;
    padding-bottom: 0px !important;*/
}


.supernav {
    color: $body-bg !important;
}

.supermenu {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
    text-align: center !important;
    cursor: pointer;
}

.supermenu-active,
.supermenu-active:hover {
    background-color: $body-bg;
    color: $body-color !important;
}

.supermenu-inactive {
    background-color: $primary;
    color: $body-bg;
}

.supermenu-inactive:hover {
    background-color: $tertiary;
    color: $body-bg;
}